import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Typography } from '@universal-tennis/ui-shared';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import FullPageModal from './FullPageModal';
import Input from '../Form/Input';
import Location from '../Form/Location';
import PasswordInput from '../Form/PasswordInput';
import Select from '../Form/Select';
import TimePicker from '../Form/TimePicker';

import { defaultGridSpacing, SharedUICategories, TypographySizes } from '../../utils/constants';
import { getAdjustedTimeOfDay } from '../../utils/display';
import { getLocationData } from '../../utils/location';
import { openClosedStatusTypes, weekdays, LeagueSessionTypes } from '../../utils/types';
import { showErrorPopup } from '../../redux/errorPopupSlice';
import { useUpdateTeamMutation } from '../../api/leaguesApi';

const EditTeamDetailsModal = ({ teamDetails, title, isOpen, onClose, onSaveClick }) => {
    const dispatch = useDispatch();
    const { SECONDARY } = SharedUICategories;
    const { X_SMALL_MEDIUM_CAP } = TypographySizes;
    const { TOURNAMENT, MULTI_ROUND } = LeagueSessionTypes;
    const [sessionTypeId, setSessionTypeId] = useState();
    const [originalLocation, setOriginalLocation] = useState();
    const [originalFormData, setOriginalFormData] = useState();

    const [updateTeam] = useUpdateTeamMutation();

    const defaultValues = {
        name: '',
        registrationPin: '',
        teamRegistrationAvailabilityStatusTypeId: 1,
        dayOfWeek: '',
        location: '',
        matchStartTimeUtc: moment(),
    };

    const {
        handleSubmit,
        formState: { errors, dirtyFields },
        control,
        reset,
    } = useForm({ defaultValues, mode: 'all' });

    useEffect(() => {
        if (teamDetails) {
            const {
                name,
                timeOfDay,
                homeCourtLocation,
                teamRegistrationAvailabilityStatusTypeId,
                registrationPin,
                sessionTypeId: teamSessionTypeId,
                dayOfWeek,
            } = teamDetails;

            let time = null;
            if (timeOfDay) {
                const adjustedTime = getAdjustedTimeOfDay({ timeOfDay });
                time = moment(adjustedTime, 'hh:mm:ss');
            }

            let timeSlotDay = '';
            if (dayOfWeek) {
                timeSlotDay = weekdays.find((weekday) => weekday.description === dayOfWeek);
            }

            const teamLocation = homeCourtLocation?.placeName || homeCourtLocation?.formattedAddress || '';
            const formData = {
                name,
                teamRegistrationAvailabilityStatusTypeId,
                location: teamLocation,
                matchStartTimeUtc: time,
                dayOfWeek: timeSlotDay.id,
                registrationPin: registrationPin || '',
            };

            setSessionTypeId(teamSessionTypeId);
            setOriginalLocation(homeCourtLocation);
            setOriginalFormData(formData);
            reset(formData);
        }
    }, []);

    const handleFormSubmit = async (formValues) => {
        const team = { ...formValues };
        const { matchStartTimeUtc, location: teamLocation, teamRegistrationAvailabilityStatusTypeId } = formValues;

        if (sessionTypeId === MULTI_ROUND) {
            const matchTime = matchStartTimeUtc.clone();
            const utcOffset = matchTime.utcOffset();
            const adjustedTime = matchTime.subtract(utcOffset, 'minutes');
            const hours = adjustedTime.get('hour');
            const minutes = adjustedTime.get('minutes');

            team.timeOfDay = `${hours}:${minutes}`;
        }

        team.teamRegistrationAvailabilityStatusTypeId = parseInt(teamRegistrationAvailabilityStatusTypeId);

        if (dirtyFields.location) {
            const formattedLocation = await getLocationData(teamLocation);
            team.homeCourtLocation = formattedLocation;
        } else {
            team.homeCourtLocation = originalLocation;
        }

        if (sessionTypeId === TOURNAMENT) {
            delete team.dayOfWeek;
            delete team.timeOfDay;
        }

        delete team.location;
        delete team.matchStartTimeUtc;
        delete team.sessionId;

        try {
            await updateTeam({ teamId: teamDetails?.id, team, sessionId: teamDetails?.sessionId }).unwrap();
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const handleOnSave = () => {
        handleSubmit(handleFormSubmit)();
        onSaveClick();
    };

    const handleOnClose = () => {
        onClose();
        reset(originalFormData);
    };

    return (
        <FullPageModal isOpen={isOpen} title={title} ctaTitle="Save" onClose={handleOnClose} onCtaClick={handleOnSave}>
            <Grid justifyContent="center" mt={3}>
                <form>
                    <Paper sx={{ px: 3, py: 2, my: 5 }}>
                        <Typography mb={2} category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>General</Typography>
                        <Grid mb={4} container spacing={defaultGridSpacing}>
                            <Grid item xs={12} lg={6}>
                                <Input
                                    disabled
                                    name="name"
                                    label="Team Name"
                                    hasError={!!errors.name}
                                    isRequired
                                    control={control}
                                />
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <Location name="location" label="Home Court" error={!!errors.location} control={control} />
                            </Grid>
                        </Grid>
                        {sessionTypeId === MULTI_ROUND && (
                            <>
                                <Typography mb={2} category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>League Play Time Slot</Typography>
                                <Grid mb={4} container spacing={defaultGridSpacing}>
                                    <Grid item xs={12} lg={6}>
                                        <Select
                                            name="dayOfWeek"
                                            label="Day"
                                            options={weekdays}
                                            labelId="day-label"
                                            isFullWidth
                                            control={control}
                                        />
                                    </Grid>
                                    <Grid item lg={6} xs={12}>
                                        <TimePicker name="matchStartTimeUtc" label="Match Start Time" control={control} />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        <Typography mb={2} category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>Team Registration</Typography>
                        <Grid container spacing={defaultGridSpacing}>
                            <Grid item xs={12} lg={6}>
                                <Select
                                    name="teamRegistrationAvailabilityStatusTypeId"
                                    label="Team player registrations"
                                    options={openClosedStatusTypes}
                                    labelId="team-player-registrations-label"
                                    isFullWidth
                                    control={control}
                                />
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <PasswordInput
                                    name="registrationPin"
                                    label="Password"
                                    helperText="You can set an optional password that your players will need in order to register for this team."
                                    control={control}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </form>
            </Grid>
        </FullPageModal>
    );
};

export default EditTeamDetailsModal;
