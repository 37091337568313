import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import moment from 'moment';

import { Typography } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';
import DateAdapter from '@mui/lab/AdapterMoment';
import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

import FullPageModal from './FullPageModal';
import Input from '../Form/Input';
import LoadingIndicator from '../LoadingIndicator';
import Location from '../Form/Location';
import Popup from '../Popups/Popup';
import Select from '../Form/Select';
import SessionMatchFormat from '../SessionMatchFormat';
import TimePicker from '../Form/TimePicker';
import { CustomSwitch } from '../Styled';
import { getTooManyMatchesPerTeamError } from '../../utils/sessions';

import {
    FrequencyTypes,
    LeagueSessionTypes,
    MatchFormatTypes,
    SessionDrawTypes,
    SessionStandingsRules,
    SessionStandingsTypes,
    StatusTypes,
    TournamentTeamsCount,
    drawTypes,
    matchFrequencyTypes,
    sessionMatchFormatTypes,
    sessionTypes,
    tournamentTeamsCountOptions,
    tennisSessionStandingsOptions,
    pickleballSessionStandingsOptions,
    SportTypeIds,
} from '../../utils/types';
import { defaultGridSpacing, SharedUICategories, TypographySizes } from '../../utils/constants';
import { showErrorPopup } from '../../redux/errorPopupSlice';
import { getLocationData } from '../../utils/location';
import { useUpdateSessionMutation, useGetLeagueFeesQuery } from '../../api/leaguesApi';
import { getAdjustedDate } from '../../utils/display';
import { setSportTypeId } from '../../redux/conferenceDetailsSlice';
import { manageSessionModalPropTypes } from '../../utils/proptypes';

const CUSTOM_MORE_MATCHES_TEXT = 'There are more matches per team than weeks in the session.';

const ManageSessionModal = ({ sessionDetails = {}, isLoadingSession = false, title = '', isOpen = false, onClose, onSaveClick }) => {
    const params = useParams();
    const theme = useTheme();
    const { SECONDARY } = SharedUICategories;
    const { X_SMALL_MEDIUM_CAP, SMALL_BOOK, X_SMALL_BOOK, MEDIUM_BOOK } = TypographySizes;
    const dispatch = useDispatch();
    const { sportTypeId } = useSelector((state) => state.conferenceDetails);

    const sessionId = params?.sessionId;
    const { OPEN, CLOSED } = StatusTypes;
    const { ONCE_A_WEEK } = FrequencyTypes;
    const { TOURNAMENT } = LeagueSessionTypes;
    const { FIRST_MATCH_BACK_DRAW } = SessionDrawTypes;
    const { BOTH, DOUBLES, SINGLES } = MatchFormatTypes;
    const { FOUR, EIGHT, SIXTEEN } = TournamentTeamsCount;
    const { TENNIS_WIN_LOSS_RECORD, PICKLEBALL_WIN_LOSS_RECORD } = SessionStandingsTypes;
    const { hasRegisteredPlayers, hasPostedResults, hasPublishedSchedule, hasSchedule } = sessionDetails || {};
    const isRestrictedSession = hasPostedResults && hasPublishedSchedule;
    const canRegister = sessionDetails?.sessionStatusTypeId === OPEN;
    const isTennisSession = sportTypeId === SportTypeIds.TENNIS;
    const standingsRuleOptions = isTennisSession ? tennisSessionStandingsOptions : pickleballSessionStandingsOptions;

    const [sessionRegistrationStatus, setSessionRegistrationStatus] = useState(true);
    const [updatedSessionRegistrationStatus, setUpdatedSessionRegistrationStatus] = useState(OPEN);
    const [startDate, setStartDate] = useState(sessionDetails?.startDateUtc);
    const [endDate, setEndDate] = useState(sessionDetails?.endDateUtc);
    const [registrationStartDate, setRegistrationStartDate] = useState(sessionDetails?.teamRegistrationStartDateUtc);
    const [registrationEndDate, setRegistrationEndDate] = useState(sessionDetails?.teamRegistrationEndDateUtc);
    const [totalPlayerCost, setTotalPlayerCost] = useState();
    const [matchFormat, setMatchFormat] = useState('');
    const [originalLocation, setOriginalLocation] = useState();
    const [originalFormData, setOriginalFormData] = useState();
    const [isTournamentSession, setIsTournamentSession] = useState(false);
    const [conferenceId, setConferenceId] = useState(parseInt(params?.conferenceId) || skipToken);
    const [leagueVerifiedFee, setLeagueVerifiedFee] = useState(0);
    const [showRuleConfirmationModal, setShowRuleConfirmationModal] = useState(false);
    const [sessionStandingsRuleId, setSessionStandingsRuleId] = useState(
        isTennisSession ? TENNIS_WIN_LOSS_RECORD : PICKLEBALL_WIN_LOSS_RECORD
    );
    const [updatedStandingsRuleId, setUpdatedStandingsRuleId] = useState(null);

    const registrationStatusDescription = sessionRegistrationStatus ? 'Open' : 'Closed';
    const [updateSession] = useUpdateSessionMutation();
    const { isLoading: isLoadingLeaguesFees, error } = useGetLeagueFeesQuery(conferenceId);

    if (error) {
        dispatch(showErrorPopup(error));
    }

    const defaultValues = {
        conferenceId: parseInt(params?.conferenceId),
        description: '',
        doublesMatchCount: '',
        doublesPointsPerMatch: '',
        doublesScoringFormatTypeId: '',
        doublesTimeAllowedTypeId: '',
        drawTypeId: FIRST_MATCH_BACK_DRAW,
        endDateUtc: moment().add(5, 'days'),
        matchesPerTeam: '',
        name: '',
        registrationFee: 0,
        registrationVerificationFee: 0,
        sessionStatusTypeId: OPEN,
        sessionDetails: SINGLES,
        sessionTypeId: '',
        singlesMatchCount: '',
        singlesPointsPerMatch: '',
        singlesScoringFormatTypeId: '',
        singlesTimeAllowedTypeId: '',
        startDateUtc: moment().add(3, 'days'),
        teamRegistrationEndDateUtc: moment().add(2, 'days').add(2, 'hour'),
        teamRegistrationStartDateUtc: moment().add(2, 'hour'),
        teamRequiredPlayersMin: 2,
        teamsMax: '',
        location: '',
        teamMatchFrequencyTypeId: ONCE_A_WEEK,
    };

    const {
        handleSubmit,
        formState: { errors, dirtyFields },
        control,
        reset,
        getValues,
        setValue,
        setError,
        clearErrors,
    } = useForm({ defaultValues, mode: 'all' });

    useEffect(() => {
        if (sessionDetails) {
            const {
                conferenceId: sessionConferenceId,
                endDateUtc,
                startDateUtc,
                registrationFee,
                teamRegistrationStartDateUtc,
                teamRegistrationEndDateUtc,
                registrationVerificationFee,
                sessionMatchTypeId,
                sessionTypeId,
                sessionStandingsTypeId,
                sessionStatusTypeId,
                description,
                drawTypeId,
                ...fetchedSession
            } = sessionDetails;

            setConferenceId(sessionConferenceId);
            setSessionStandingsRuleId(sessionStandingsTypeId ?? sessionStandingsRuleId);

            let sessionLocation = '';
            const isTournament = sessionTypeId === TOURNAMENT;
            if (isTournament) {
                sessionLocation = sessionDetails?.location?.placeName || '';
                setOriginalLocation(sessionDetails?.location);
                setIsTournamentSession(true);
            }

            let formData;

            if (sessionMatchTypeId !== BOTH) {
                formData = {
                    ...defaultValues,
                    ...fetchedSession,
                    sessionTypeId,
                    drawTypeId,
                    description: description || '',
                    registrationFee,
                    sessionStatusTypeId,
                    registrationVerificationFee,
                    sessionMatchTypeId,
                    location: sessionLocation,
                    endDateUtc: getAdjustedDate({ dateUtc: endDateUtc, format: null, adjustForDSL: false }),
                    startDateUtc: getAdjustedDate({ dateUtc: startDateUtc, format: null, adjustForDSL: false }),
                    teamRegistrationStartDateUtc: getAdjustedDate({
                        dateUtc: teamRegistrationStartDateUtc,
                        format: null,
                        adjustForDSL: false,
                    }),
                    teamRegistrationEndDateUtc: getAdjustedDate({ dateUtc: teamRegistrationEndDateUtc, format: null, adjustForDSL: false }),
                };
            } else {
                formData = {
                    ...fetchedSession,
                    drawTypeId,
                    registrationFee,
                    registrationVerificationFee,
                    sessionMatchTypeId,
                    sessionStatusTypeId,
                    sessionTypeId,
                    description: description || '',
                    location: sessionLocation,
                    endDateUtc: getAdjustedDate({ dateUtc: endDateUtc, format: null, adjustForDSL: false }),
                    startDateUtc: getAdjustedDate({ dateUtc: startDateUtc, format: null, adjustForDSL: false }),
                    teamRegistrationStartDateUtc: getAdjustedDate({
                        dateUtc: teamRegistrationStartDateUtc,
                        format: null,
                        adjustForDSL: false,
                    }),
                    teamRegistrationEndDateUtc: getAdjustedDate({ dateUtc: teamRegistrationEndDateUtc, format: null, adjustForDSL: false }),
                };
            }

            setSessionRegistrationStatus(canRegister);
            setTotalPlayerCost(parseInt(registrationFee) + parseInt(registrationVerificationFee));
            setLeagueVerifiedFee(registrationVerificationFee);
            setOriginalFormData(formData);
            reset(formData);
            setMatchFormat(sessionMatchTypeId);
            dispatch(setSportTypeId(sessionDetails?.sportTypeId));
        }
    }, [sessionDetails]);

    const extractDate = (dateTimeStr) => {
        return getAdjustedDate({ dateUtc: moment(dateTimeStr).toISOString(), format: 'YYYY-MM-DD' });
    };

    const extractTime = (dateUtc) => {
        const dateTimeStr = getAdjustedDate({ dateUtc, format: null, adjustForDSL: true }).toISOString();
        const tIndex = dateTimeStr.indexOf('T');
        return dateTimeStr.substring(tIndex);
    };

    const onHandleRegistrationStatusSwitch = () => {
        const newId = updatedSessionRegistrationStatus === OPEN ? CLOSED : OPEN;
        setSessionRegistrationStatus((prevStatus) => !prevStatus);
        setUpdatedSessionRegistrationStatus(newId);
    };

    const handleFormSubmit = async (formValues) => {
        const session = { ...formValues };

        session.startDateUtc = `${extractDate(startDate)}${extractTime(session.startDateUtc)}`;
        session.endDateUtc = `${extractDate(endDate)}${extractTime(session.endDateUtc)}`;
        session.teamRegistrationStartDateUtc = `${extractDate(registrationStartDate)}${extractTime(session.teamRegistrationStartDateUtc)}`;
        session.teamRegistrationEndDateUtc = `${extractDate(registrationEndDate)}${extractTime(session.teamRegistrationEndDateUtc)}`;
        session.description = session.description.trim().length === 0 ? null : session.description;
        session.sessionMatchTypeId = matchFormat;
        session.sessionStatusTypeId = updatedSessionRegistrationStatus;
        session.sessionStandingsTypeId = sessionStandingsRuleId;

        if (matchFormat === SINGLES) {
            delete session.doublesMatchCount;
            delete session.doublesPointsPerMatch;
            delete session.doublesScoringFormatTypeId;
            delete session.doublesTimeAllowedTypeId;
        }

        if (matchFormat === DOUBLES) {
            delete session.singlesMatchCount;
            delete session.singlesPointsPerMatch;
            delete session.singlesScoringFormatTypeId;
            delete session.singlesTimeAllowedTypeId;
        }

        const sessionType = getValues('sessionTypeId');

        if (sessionType === TOURNAMENT) {
            delete session.teamMatchFrequencyTypeId;

            if (dirtyFields.location) {
                session.location = await getLocationData(formValues.location);
            } else {
                session.location = originalLocation;
            }
        } else {
            delete session.location;
        }

        try {
            await updateSession({ sessionId, session, conferenceId }).unwrap();
        } catch (apiError) {
            dispatch(showErrorPopup(apiError));
        }
    };

    const handleEntryFeeChange = (e) => {
        const feeValue = parseInt(e.target.value);
        if (!Number.isNaN(feeValue)) {
            setTotalPlayerCost(parseInt(e.target.value) + leagueVerifiedFee);
        } else {
            setTotalPlayerCost(leagueVerifiedFee);
        }
    };

    const handleOnMatchFormatChange = (event) => {
        setMatchFormat(event.target.value);
    };

    const handleOnEventStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleOnEventEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleOnRegistrationStartDateChange = (date) => {
        setRegistrationStartDate(date);
    };

    const handleOnRegistrationEndDateChange = (date) => {
        setRegistrationEndDate(date);
    };

    const handleOnSessionTypeChange = (event) => {
        const isTournament = event.target.value === TOURNAMENT;
        setIsTournamentSession(isTournament);
    };

    const handleMatchesPerTeamCheck = (event) => {
        clearErrors('matchesPerTeam');
        setValue('matchesPerTeam', event.target.value);
        const session = getValues();

        const hasErrors = getTooManyMatchesPerTeamError({
            ...session,
            matchesPerTeam: +event.target.value,
        });

        if (hasErrors) {
            setError('matchesPerTeam', { type: 'custom', message: CUSTOM_MORE_MATCHES_TEXT });
        }
    };

    const handleOnTournamentTeamsChange = (event) => {
        const teamsCount = event.target.value;
        switch (teamsCount) {
            case FOUR:
                setValue('matchesPerTeam', 2);
                break;
            case EIGHT:
                setValue('matchesPerTeam', 4);
                break;
            case SIXTEEN:
                setValue('matchesPerTeam', 8);
                break;
            default:
                break;
        }
    };

    const handleOnSave = () => {
        handleSubmit(handleFormSubmit)();
        onSaveClick();
    };

    const handleOnClose = () => {
        setSessionRegistrationStatus(canRegister);
        setMatchFormat(sessionDetails?.sessionMatchTypeId);
        reset(originalFormData);
        onClose();
    };

    const handleOnSessionRuleChange = (event) => {
        const ruleValue = event?.target?.value;

        if (ruleValue !== sessionStandingsRuleId) {
            setShowRuleConfirmationModal(true);
            setUpdatedStandingsRuleId(ruleValue);
        }
    };

    const handleOnConfirmRuleChange = () => {
        setShowRuleConfirmationModal(false);
        setSessionStandingsRuleId(updatedStandingsRuleId);
    };

    const handleOnCloseRuleModal = () => {
        setShowRuleConfirmationModal(false);
        setUpdatedStandingsRuleId(null);
    };

    return (
        <FullPageModal isOpen={isOpen} title={title} ctaTitle="Save" onClose={handleOnClose} onCtaClick={handleOnSave}>
            <Grid justifyContent="center" mt={3} mb={10}>
                {isLoadingSession && isLoadingLeaguesFees ? (
                    <LoadingIndicator />
                ) : (
                    <form>
                        <Typography mb={2} category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>
                            General
                        </Typography>
                        <Paper sx={{ p: 2, mb: 4, boxShadow: 'none' }}>
                            <Grid container spacing={defaultGridSpacing}>
                                <Grid item xs={12} lg={4}>
                                    <Input name="name" label="Session Name" isRequired hasError={!!errors.name} control={control} />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Select
                                        isRequired
                                        disabled={isRestrictedSession}
                                        hasError={!!errors.sessionTypeId}
                                        name="sessionTypeId"
                                        label="Session Type"
                                        labelId="session-type-label"
                                        options={sessionTypes}
                                        control={control}
                                        onSelectChange={handleOnSessionTypeChange}
                                        isFullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    {!isTournamentSession && (
                                        <FormControl fullWidth>
                                            <InputLabel id="session-standings-rules-label">Team Standings Rule</InputLabel>
                                            <MuiSelect
                                                onChange={handleOnSessionRuleChange}
                                                value={sessionStandingsRuleId}
                                                label="Team Standings Rule"
                                                labelId="session-standings-rules-label"
                                            >
                                                {standingsRuleOptions.map((rule) => (
                                                    <MenuItem key={rule?.id} value={rule?.id}>
                                                        {rule?.description}
                                                    </MenuItem>
                                                ))}
                                            </MuiSelect>
                                        </FormControl>
                                    )}
                                    {isTournamentSession && (
                                        <Select
                                            disabled={hasSchedule}
                                            name="drawTypeId"
                                            label="Draw Type"
                                            labelId="draw-type-label"
                                            options={drawTypes}
                                            control={control}
                                            isFullWidth
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            {isTournamentSession && (
                                <Grid my={1} container spacing={defaultGridSpacing}>
                                    <Grid item xs={12}>
                                        <Location
                                            name="location"
                                            label="Team Tournament Location"
                                            error={!!errors.location}
                                            control={control}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            {!isTournamentSession && (
                                <Grid my={1} container spacing={defaultGridSpacing}>
                                    <Grid item xs={12}>
                                        <Select
                                            disabled={isRestrictedSession}
                                            name="teamMatchFrequencyTypeId"
                                            label="Match Frequency"
                                            labelId="match-frequency-type-label"
                                            options={matchFrequencyTypes}
                                            control={control}
                                            isFullWidth
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            <Grid my={1} container spacing={defaultGridSpacing}>
                                <Grid item xs={12} lg={3}>
                                    <LocalizationProvider dateAdapter={DateAdapter}>
                                        <DatePicker
                                            name="startDateUtc"
                                            label="Session Start Date"
                                            value={startDate}
                                            onChange={handleOnEventStartDateChange}
                                            control={control}
                                            renderInput={(inputProps) => <TextField required {...inputProps} fullWidth error={false} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <TimePicker name="startDateUtc" label="Session Start Time" control={control} />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <LocalizationProvider dateAdapter={DateAdapter}>
                                        <DatePicker
                                            name="endDateUtc"
                                            label="Session End Date"
                                            value={endDate}
                                            onChange={handleOnEventEndDateChange}
                                            control={control}
                                            renderInput={(inputProps) => <TextField required {...inputProps} fullWidth error={false} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <TimePicker name="endDateUtc" label="Session End Time" control={control} />
                                </Grid>
                            </Grid>
                            <Grid my={1} container spacing={defaultGridSpacing}>
                                <Grid item xs={12}>
                                    <Input
                                        control={control}
                                        name="description"
                                        multiline
                                        minRows={3}
                                        hasError={!!errors.description}
                                        label="Description"
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                        <Typography mb={2} category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>
                            Team Match Format
                        </Typography>
                        <Paper sx={{ p: 2, mb: 4, boxShadow: 'none' }}>
                            <Grid container alignItems="center" spacing={defaultGridSpacing}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="match-format-label">Format Type</InputLabel>
                                        <MuiSelect
                                            disabled={isRestrictedSession}
                                            onChange={handleOnMatchFormatChange}
                                            value={matchFormat}
                                            label="Format Type"
                                            labelId="match-format-label"
                                        >
                                            {sessionMatchFormatTypes.map((session) => (
                                                <MenuItem key={session.id} value={session.id}>
                                                    {session.description}
                                                </MenuItem>
                                            ))}
                                        </MuiSelect>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <SessionMatchFormat
                                sportTypeId={sportTypeId}
                                isDisabled={isRestrictedSession}
                                matchFormat={matchFormat}
                                control={control}
                                errors={errors}
                            />
                        </Paper>
                        <Typography mb={2} category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>
                            Team Registration
                        </Typography>
                        <Paper sx={{ p: 2, mb: 4, boxShadow: 'none' }}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Box>
                                    <Typography category={SECONDARY} size={SMALL_BOOK}>
                                        Session Status
                                    </Typography>
                                    <Typography category={SECONDARY} size={X_SMALL_BOOK}>
                                        Teams can be added to an open session
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" gap={1}>
                                    <Typography category={SECONDARY} size={SMALL_BOOK}>
                                        {registrationStatusDescription}
                                    </Typography>
                                    <CustomSwitch
                                        name="sessionStatusTypeId"
                                        control={control}
                                        checked={sessionRegistrationStatus}
                                        inputProps={{ 'aria-label': `Switch` }}
                                        onChange={onHandleRegistrationStatusSwitch}
                                    />
                                </Box>
                            </Grid>
                            <Grid my={1} container spacing={defaultGridSpacing}>
                                <Grid item xs={12} lg={3}>
                                    <LocalizationProvider dateAdapter={DateAdapter}>
                                        <DatePicker
                                            name="teamRegistrationStartDateUtc"
                                            label="Registration Start Date"
                                            value={registrationStartDate}
                                            onChange={handleOnRegistrationStartDateChange}
                                            control={control}
                                            renderInput={(inputProps) => <TextField required {...inputProps} fullWidth error={false} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <TimePicker name="teamRegistrationStartDateUtc" label="Registration Start Time" control={control} />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <LocalizationProvider dateAdapter={DateAdapter}>
                                        <DatePicker
                                            name="teamRegistrationEndDateUtc"
                                            label="Registration End Date"
                                            value={registrationEndDate}
                                            onChange={handleOnRegistrationEndDateChange}
                                            control={control}
                                            renderInput={(inputProps) => <TextField required {...inputProps} fullWidth error={false} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <TimePicker name="teamRegistrationEndDateUtc" label="Registration End Time" control={control} />
                                </Grid>
                            </Grid>
                            <Grid my={1} container spacing={defaultGridSpacing}>
                                <Grid item xs={12} lg={6}>
                                    {isTournamentSession ? (
                                        <Select
                                            onSelectChange={handleOnTournamentTeamsChange}
                                            isRequired
                                            disabled={isRestrictedSession}
                                            hasError={!!errors.teamsMax}
                                            name="teamsMax"
                                            label="Max Teams"
                                            labelId="tournament-max-teams-label"
                                            options={tournamentTeamsCountOptions}
                                            control={control}
                                            isFullWidth
                                        />
                                    ) : (
                                        <Input
                                            isRequired
                                            disabled={isRestrictedSession}
                                            name="teamsMax"
                                            label="Max Teams"
                                            type="number"
                                            hasError={!!errors.teamsMax}
                                            inputProps={{ min: 0 }}
                                            control={control}
                                        />
                                    )}
                                </Grid>
                                {!isTournamentSession && (
                                    <Grid item xs={12} lg={6}>
                                        <Input
                                            disabled={isRestrictedSession}
                                            isRequired
                                            name="matchesPerTeam"
                                            label="Matches Per Team"
                                            type="number"
                                            hasError={!!errors.matchesPerTeam}
                                            inputProps={{ min: 0 }}
                                            control={control}
                                            onChange={handleMatchesPerTeamCheck}
                                        />
                                        {errors.matchesPerTeam && (
                                            <Typography category={SECONDARY} size={X_SMALL_BOOK} color={theme.appColors.red}>
                                                {errors.matchesPerTeam.message}
                                            </Typography>
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                        </Paper>
                        <Typography mb={2} category={SECONDARY} size={X_SMALL_MEDIUM_CAP}>
                            Player Fees
                        </Typography>
                        <Paper sx={{ p: 2, mb: 4, boxShadow: 'none' }}>
                            <Box mb={2} display="flex" justifyContent="flex-end">
                                <Box display="flex" alignItems="center" mr={2}>
                                    <Typography category={SECONDARY} size={MEDIUM_BOOK}>
                                        Session Player Entry Fee
                                    </Typography>
                                </Box>
                                <Controller
                                    name="registrationFee"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            disabled={hasRegisteredPlayers}
                                            value={value}
                                            variant="filled"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            inputProps={{ style: { textAlign: 'right', width: 55 } }}
                                            onChange={(e) => {
                                                onChange(e);
                                                handleEntryFeeChange(e);
                                            }}
                                        />
                                    )}
                                />
                            </Box>
                            <Typography mr={1.2} align="right" category={SECONDARY} size={SMALL_BOOK}>
                                {`League Fee: $${leagueVerifiedFee}`}
                            </Typography>
                            <Typography mr={1.2} align="right" category={SECONDARY} size={SMALL_BOOK}>
                                {`Total per Player: $${totalPlayerCost}`}
                            </Typography>
                        </Paper>
                    </form>
                )}
                <Popup
                    onAction={handleOnConfirmRuleChange}
                    actionLabel="Yes, change"
                    buttonLabel="No, cancel"
                    open={showRuleConfirmationModal}
                    onClose={handleOnCloseRuleModal}
                    title="Confirm standings rule"
                    message={`Are you sure you want to change the team standings rule to ${SessionStandingsRules[updatedStandingsRuleId]}?`}
                />
            </Grid>
        </FullPageModal>
    );
};

ManageSessionModal.propTypes = { ...manageSessionModalPropTypes };

export default ManageSessionModal;
