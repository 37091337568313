import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';

import { Typography } from '@universal-tennis/ui-shared';

import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import LoadingIndicator from '../LoadingIndicator';
import PlayersTable from '../Tables/PlayersTable';
import PlayerTab from './PlayersTab';

import { groupPlayersByStatus } from '../../utils/teams';
import { showErrorPopup } from '../../redux/errorPopupSlice';
import { useGetPlayersQuery, useGetSessionTeamsQuery } from '../../api/leaguesApi';
import { TypographySizes, SharedUICategories } from '../../utils/constants';
import { playerTabPropTypes } from '../../utils/proptypes';

const PlayersTab = ({
    sessionId,
    onWithdrawPlayer,
    onMovePlayer,
    onCopyPlayer,
    onTogglePlayerStatus,
    isManaging = false,
    hasSessionEnded,
    isUpdating,
    displaySinglesAndDoublesRecord,
}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { MEDIUM_MEDIUM } = TypographySizes;
    const { SECONDARY } = SharedUICategories;
    const [teamId, setTeamId] = useState('');
    const [teams, setTeams] = useState([]);
    const [players, setPlayers] = useState([]);

    const {
        data: teamPlayers,
        isLoading: isLoadingPlayers,
        error: playersError,
        isFetchingPlayers,
    } = useGetPlayersQuery(teamId, { skip: !teamId });
    const { data: sessionTeams, isFetching: isLoadingSessionTeams, error: teamsError } = useGetSessionTeamsQuery(sessionId);

    const isLoading = isUpdating || isLoadingPlayers || isLoadingSessionTeams || isFetchingPlayers;

    if (teamsError) {
        dispatch(showErrorPopup(teamsError));
    }

    if (playersError) {
        dispatch(showErrorPopup(playersError));
    }

    useEffect(() => {
        if (sessionTeams) {
            const teamSelections = sessionTeams?.teams?.map((team) => {
                return {
                    id: team.id,
                    description: team.name,
                };
            });
            setTeams(teamSelections);
        }
    }, [sessionTeams]);

    useEffect(() => {
        if (teamPlayers) {
            const groupedPlayers = groupPlayersByStatus(teamPlayers);
            setPlayers(groupedPlayers);
        }
    }, [teamPlayers]);

    const handleOnTeamChange = (event) => {
        setTeamId(event.target.value);
    };

    const showNoResults = !players?.rostered?.length && !players?.substitutes?.length && teamId && !isLoading;
    const isFirstSearch = !teamId && !players?.length && !isLoading;

    const renderContent = () => {
        return (
            <>
                {isFirstSearch && (
                    <Typography align="center" category={SECONDARY} size={MEDIUM_MEDIUM} color={theme.appColors.black}>
                        Select team from dropdown.
                    </Typography>
                )}
                {showNoResults && (
                    <Typography align="center" category={SECONDARY} size={MEDIUM_MEDIUM} color={theme.appColors.black}>
                        No players have registered for this team.
                    </Typography>
                )}
                {!!players?.rostered?.length && (
                    <Box>
                        {displaySinglesAndDoublesRecord ? (
                            <PlayerTab teamId={teamId} />
                        ) : (
                            <>
                                <Typography category={SECONDARY} size={MEDIUM_MEDIUM} color={theme.appColors.black}>
                                    Rostered
                                </Typography>
                                <PlayersTable
                                    hasSessionEnded={hasSessionEnded}
                                    isManaging={isManaging}
                                    teamPlayers={players.rostered}
                                    onWithdrawPlayer={onWithdrawPlayer}
                                    onMovePlayer={onMovePlayer}
                                    onCopyPlayer={onCopyPlayer}
                                    onTogglePlayerStatus={onTogglePlayerStatus}
                                />
                            </>
                        )}
                    </Box>
                )}
                {!!players?.substitutes?.length && (
                    <Box mt={5}>
                        {displaySinglesAndDoublesRecord ? (
                            <PlayerTab teamId={teamId} />
                        ) : (
                            <>
                                <Typography category={SECONDARY} size={MEDIUM_MEDIUM} color={theme.appColors.black}>
                                    Substitutes
                                </Typography>
                                <PlayersTable
                                    hasSessionEnded={hasSessionEnded}
                                    isManaging={isManaging}
                                    teamPlayers={players.substitutes}
                                    onWithdrawPlayer={onWithdrawPlayer}
                                    onMovePlayer={onMovePlayer}
                                    onCopyPlayer={onCopyPlayer}
                                    onTogglePlayerStatus={onTogglePlayerStatus}
                                />
                            </>
                        )}
                    </Box>
                )}
            </>
        );
    };

    return (
        <Box mt={4}>
            {!teams?.length && !isLoading ? (
                <Box py={4} textAlign="center">
                    <Typography category={SECONDARY} size={MEDIUM_MEDIUM} color={theme.appColors.black}>
                        No teams have been created for this session.
                    </Typography>
                </Box>
            ) : (
                <>
                    <FormControl sx={{ width: 300, mb: 4 }}>
                        <InputLabel id="team-select">Select Team</InputLabel>
                        <Select value={teamId} disabled={isLoading} label="Select Team" labelId="team-select" onChange={handleOnTeamChange}>
                            {teams.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.description}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {isLoading ? <LoadingIndicator /> : renderContent()}
                </>
            )}
        </Box>
    );
};

PlayersTab.propTypes = {
    ...playerTabPropTypes,
};

export default PlayersTab;
