/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-string-refs */
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import 'url-search-params-polyfill';
import { CancelToken, isCancel } from 'axios';

import Button from '@mui/material/Button';

import BackgroundImage from '../BackgroundImage';
import Description from './Description';
import Img from '../Img';
import SearchIcon from '../../assets/icons/icon-search.svg';
import VerifiedIcon from '../../assets/icons/icon-verified-blue.svg';
import PlayerIcon from '../../assets/icons/icon-avatar-player.svg';
import ClubIcon from '../../assets/icons/icon-avatar-club.svg';
import CollegeIcon from '../../assets/icons/icon-avatar-college.svg';
import EventIcon from '../../assets/icons/icon-avatar-event.svg';
import SchoolIcon from '../../assets/icons/icon-avatar-school.svg';

import UtrIcon from '../Icons/UtrIcon';
import config from '../../config';

import { getApiAxiosInstance } from '../../utils/network';
import { getEventDateString, getRatingDetails, updateRatingObject } from './utils';
import { LeagueTypes } from '../../utils/types';
import { useStatsig } from '../../hooks/StatsigContextProvider';

import styles from './globalSearch.module.scss';
import { getClickAttrs } from '../../utils/analytics';

const { appHost, apiHost } = config;
const defaultSearchState = {
    clubs: { total: 0, hits: [] },
    colleges: { total: 0, hits: [] },
    events: { total: 0, hits: [] },
    highSchools: { total: 0, hits: [] },
    players: { total: 0, hits: [] },
};

const GlobalSearch = () => {
    const { statsig } = useStatsig();
    const navigate = useNavigate();
    const cancelRef = useRef(null);
    const wrapperRef = useRef(null);
    const queryRef = useRef(null);
    const { preferences } = useSelector((state) => state?.user);
    const { UT_ADULT_TEAM_TENNIS, UT_JUNIOR_TEAM_TENNIS, UT_ADULT_TEAM_PICKLEBALL, UT_JUNIOR_TEAM_PICKLEBALL } = LeagueTypes;
    const [isLoading, setIsLoading] = useState(false);
    const [showDropDown, setShowDropDown] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [hasSearched, setHasSearched] = useState(false);
    const [searchState, setSearchState] = useState(defaultSearchState);
    const maxResultsForSection = 4;
    const { clubs, colleges, events, highSchools, players } = searchState;
    const isAdultTeamPickballEnabled = statsig?.featureGates?.adultTeamPickleball;
    const isJuniorTeamPickballEnabled = statsig?.featureGates?.juniorTeamPickleball;

    const hasNoResults =
        clubs.total === 0 &&
        colleges.total === 0 &&
        events.total === 0 &&
        highSchools.total === 0 &&
        players.total === 0 &&
        !isLoading &&
        hasSearched &&
        searchQuery;

    const hasSomeResults =
        clubs.total > 0 || colleges.total > 0 || events.total > 0 || highSchools.total > 0 || players.total > 0 || isLoading;

    useEffect(() => {
        const getSearchResults = async () => {
            const axiosInstance = getApiAxiosInstance();
            const params = new URLSearchParams();

            params.append('query', searchQuery);
            params.append('top', 10);
            params.append('skip', 0);
            params.append('schoolClubSearch', true);

            const searchUrl = `${apiHost}/v2/search?${params}`;
            const cancel = cancelRef.current;

            if (cancel) {
                cancel();
            }

            const response = await axiosInstance
                .get(searchUrl, {
                    cancelToken: new CancelToken((c) => {
                        cancelRef.current = c;
                    }),
                    params,
                })
                .catch((error) => {
                    if (!isCancel(error)) {
                        setIsLoading(false);
                    }
                });

            if (response?.data) {
                const { data } = response;
                const stateToSet = {
                    clubs: data?.clubs,
                    events: data?.events,
                    players: data?.players,
                    highSchools: data?.highSchools,
                    colleges: data?.highSchools,
                };
                setSearchState(stateToSet);
                setIsLoading(false);
                setHasSearched(true);
            }
        };
        if (isLoading && !!searchQuery.length) {
            getSearchResults();
        }
    }, [isLoading, searchQuery]);

    const handleClickOutside = useCallback((event) => {
        if (wrapperRef && !wrapperRef.current.contains(event.target)) {
            setShowDropDown(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    const handleOnFocus = () => {
        setShowDropDown(true);
        queryRef.current.value = searchQuery;
    };

    const handleKeyUp = (event) => {
        const query = event.target.value;

        if (query.length > 0) {
            if (event.key !== 'Enter') {
                setIsLoading(true);
                setShowDropDown(true);
                setSearchQuery(query);
            }
        } else {
            setSearchQuery('');
        }
        setSearchState(defaultSearchState);
        setShowDropDown(true);
    };

    const handlePaste = (event) => {
        const { target } = event;
        setTimeout(() => {
            const query = target.value.trim();
            if (query.length > 0) {
                if (event.key !== 'Enter') {
                    setIsLoading(true);
                    setShowDropDown(true);
                    setSearchQuery(query);
                }
            } else {
                setSearchQuery('');
            }
            setSearchState(defaultSearchState);
            setShowDropDown(true);
        }, 200);
    };

    const handleSeeAllClick = async (event, typeFilter) => {
        if (event) {
            event.preventDefault();
        }
        setShowDropDown(false);

        const params = new URLSearchParams();

        if (searchQuery) {
            params.append('query', searchQuery);
        }
        params.append('utrMin', 1);
        params.append('utrMax', 16);
        params.append('utrType', 'verified');
        params.append('utrTeamType', 'singles');
        params.append('utrFitPosition', 6);
        params.append('type', typeFilter);

        window.location = `${appHost}/search?${params}`;
    };

    const handleClearClick = () => {
        queryRef.current.value = '';
        setSearchState(defaultSearchState);
        setSearchQuery('');
        setShowDropDown(false);
        setHasSearched(false);

        setTimeout(() => {
            queryRef.current.focus();
        }, 200);
    };

    const handlePlayerClick = (player) => {
        window.location = `${appHost}/profiles/${player.id}`;
    };

    const handleEventClick = (event) => {
        window.location = `${appHost}/events/${event.id}`;
    };

    const handleClubClick = (club) => {
        window.location = `${appHost}/clubs/${club.id}`;
    };

    const handleTypeFilterClick = (typeFilter) => {
        setShowDropDown(false);
        window.location = `${appHost}/search?utrMin=1&utrMax=16&utrType=verified&utrTeamType=singles&utrFitPosition=6&type=${typeFilter}`;
    };

    const handleTeamLeagueSearchClick = (leagueId) => (event) => {
        event.preventDefault();
        setShowDropDown(false);
        navigate(`/search/league/${leagueId}`);
    };

    const renderPlayer = (player, index) => {
        const { displayName, descriptionShort, gender, location, playerProfileImages } = player;
        const userIsPowered = false;
        const rating = getRatingDetails(updateRatingObject({ player }), userIsPowered);
        let description = '';

        if (descriptionShort) {
            description = [descriptionShort];
        } else {
            description = location && location.display ? [location.display] : [];
        }
        const descriptionItems = [...(gender ? [gender.charAt(0)] : []), ...description];

        return (
            index < maxResultsForSection && (
                <div
                    className={`row ${styles.globalSearchDropdownOption}`}
                    onClick={() => handlePlayerClick(player)}
                    key={`p${index}`}
                    {...getClickAttrs({
                        category: 'Global Search Result',
                        context: `Player Clicked - playerId: ${player.id} - query: ${searchQuery}`,
                        location: 'Global Search',
                    })}
                >
                    <div className="col-2">
                        <BackgroundImage
                            baseUrl="/v1/player/"
                            defaultImage={PlayerIcon}
                            images={playerProfileImages}
                            imageType="thumbnail"
                            extraCls="playerThumbnailImage"
                        />
                    </div>
                    <div className="col-10">
                        <div className={`${styles.name} show-ellipsis`}>
                            <span>{`${displayName} `}</span>
                            {rating.verified ? <UtrIcon icon={VerifiedIcon} size="16px" alt="verified" /> : ''}
                        </div>
                        <div className={`${styles.place} show-ellipsis`}>
                            <Description descriptionItems={descriptionItems} />
                        </div>
                    </div>
                </div>
            )
        );
    };

    const renderSchool = (schoolClub, index) => {
        const { id, name, location, profilePhotoUrl, isCollege } = schoolClub;
        const { displayName, shortName } = schoolClub.school;
        const photoUrl = profilePhotoUrl ? `/v1/club/${profilePhotoUrl}?variant=icon` : null;
        const descriptionItems = [...(location && location.display ? [location.display] : [])];
        const schoolName = name || displayName || shortName;
        return (
            index < maxResultsForSection && (
                <a
                    href={`/schools/${id}`}
                    key={`s${index}`}
                    {...getClickAttrs({
                        category: 'Global Search Result',
                        context: `School Clicked - id: ${id} - query: ${searchQuery}`,
                        location: 'Global Search',
                    })}
                >
                    <div className={`row ${styles.globalSearchDropdownOption}`}>
                        <div className="col-2">
                            {photoUrl ? (
                                <Img
                                    className={styles.globalSearchDropdownOptionImage}
                                    src={photoUrl}
                                    srcSet={`${photoUrl}&size=1x, ${photoUrl}&size=2x 2x, ${photoUrl}&size=3x 3x`}
                                    onError={(e) => {
                                        e.target.src = '/img/v2/icons/icon-utr-logo.svg';
                                        e.target.srcset = '/img/v2/icons/icon-utr-logo.svg';
                                    }}
                                />
                            ) : (
                                <Img className={styles.globalSearchDropdownOptionImage} src={isCollege ? CollegeIcon : SchoolIcon} />
                            )}
                        </div>
                        <div className="col-10">
                            <p className={`${styles.name} show-ellipsis d-block d-md-none`}>{schoolName}</p>
                            <p className={`${styles.name} show-ellipsis d-none d-md-block`}>{schoolName}</p>
                            <div className={`${styles.place} show-ellipsis`}>
                                <Description descriptionItems={descriptionItems} />
                            </div>
                        </div>
                    </div>
                </a>
            )
        );
    };

    const renderClub = (club, index) => {
        const { id, name, location, memberCount } = club;
        const descriptionItems = [
            ...(location && location.display ? [location && location.display] : []),
            ...(memberCount && memberCount >= 10 ? [`${memberCount} member${memberCount && memberCount > 1 ? 's' : ''}`] : []),
        ];
        return index < 4 ? (
            <div
                className={`row ${styles.globalSearchDropdownOption}`}
                onClick={() => handleClubClick(club)}
                key={`c${index}`}
                {...getClickAttrs({
                    category: 'Global Search Result',
                    context: `Club Clicked - id: ${id} - query: ${searchQuery}`,
                    location: 'Global Search',
                })}
            >
                <div className="col-2">
                    <Img className={styles.globalSearchDropdownOptionImage} src={ClubIcon} />
                </div>
                <div className="col-10">
                    <p className={`${styles.name} show-ellipsis`}>{name}</p>
                    <div className={`${styles.place} show-ellipsis`}>
                        <Description descriptionItems={descriptionItems} />
                    </div>
                </div>
            </div>
        ) : null;
    };

    const renderEvent = (event, index) => {
        const { id, name, eventSchedule, eventState } = event;
        const descriptionItems = [];
        if (eventSchedule) {
            descriptionItems.push(getEventDateString(eventSchedule, eventState && eventState.value === 'completed'));
        }
        return (
            index < maxResultsForSection && (
                <div
                    className={`row ${styles.globalSearchDropdownOption}`}
                    onClick={() => handleEventClick(event)}
                    key={`e${index}`}
                    {...getClickAttrs({
                        category: 'Global Search Result',
                        context: `Event Clicked - id: ${id} - query: ${searchQuery}`,
                        location: 'Global Search',
                    })}
                >
                    <div className="col-2">
                        <Img className={styles.globalSearchDropdownOptionImage} src={EventIcon} />
                    </div>
                    <div className="col-10">
                        <p className={`${styles.name} show-ellipsis`}>{name}</p>
                        <div className={`${styles.place} show-ellipsis`}>
                            <Description descriptionItems={descriptionItems} />
                        </div>
                    </div>
                </div>
            )
        );
    };

    const renderDropdown = () => {
        const hasQuery = !!searchQuery.length;
        const showTennisPills = !!preferences?.showTennisContent;
        const showPickleballPills = !!preferences?.showPickleballContent;
        return (
            <div className={styles.globalSearchDropDown}>
                {!hasQuery && (
                    <div className="filter-wrapper">
                        <div className="filter-title text-highlight4">What are you looking for?</div>
                        <div className="filter-buttons">
                            <Button
                                variant="dark"
                                size="small"
                                onClick={() => handleTypeFilterClick('players')}
                                {...getClickAttrs({
                                    category: 'Global Search Category',
                                    context: `Players`,
                                    location: 'Global search CTA',
                                })}
                            >
                                Players
                            </Button>
                            <Button
                                variant="dark"
                                size="small"
                                onClick={() => handleTypeFilterClick('events')}
                                {...getClickAttrs({
                                    category: 'Global Search Category',
                                    context: `All Events`,
                                    location: 'Global search CTA',
                                })}
                            >
                                All Events
                            </Button>
                            {showTennisPills && (
                                <>
                                    <Button
                                        variant="dark"
                                        size="small"
                                        onClick={() => handleTypeFilterClick('flexLeagues')}
                                        {...getClickAttrs({
                                            category: 'Global Search Category',
                                            context: `Flex Leagues`,
                                            location: 'Global search CTA',
                                        })}
                                    >
                                        Flex Leagues
                                    </Button>
                                    <Button
                                        variant="dark"
                                        size="small"
                                        onClick={handleTeamLeagueSearchClick(UT_ADULT_TEAM_TENNIS)}
                                        {...getClickAttrs({
                                            category: 'Global Search Category',
                                            context: `Adult Team Tennis`,
                                            location: 'Global search CTA',
                                        })}
                                    >
                                        Adult Team Tennis
                                    </Button>
                                    <Button
                                        variant="dark"
                                        size="small"
                                        onClick={handleTeamLeagueSearchClick(UT_JUNIOR_TEAM_TENNIS)}
                                        {...getClickAttrs({
                                            category: 'Global Search Category',
                                            context: `Junior Team Tennis`,
                                            location: 'Global search CTA',
                                        })}
                                    >
                                        Junior Team Tennis
                                    </Button>
                                    <Button
                                        variant="dark"
                                        size="small"
                                        onClick={() => handleTypeFilterClick('proTennisTour')}
                                        {...getClickAttrs({
                                            category: 'Global Search Category',
                                            context: `UTR Pro Tennis Tour`,
                                            location: 'Global search CTA',
                                        })}
                                    >
                                        UTR Pro Tennis Tour
                                    </Button>
                                </>
                            )}
                            <Button
                                variant="dark"
                                size="small"
                                onClick={() => handleTypeFilterClick('clubs')}
                                {...getClickAttrs({
                                    category: 'Global Search Category',
                                    context: `Clubs`,
                                    location: 'Global search CTA',
                                })}
                            >
                                Clubs
                            </Button>
                            {showTennisPills && (
                                <>
                                    <Button
                                        variant="dark"
                                        size="small"
                                        onClick={() => handleTypeFilterClick('colleges')}
                                        {...getClickAttrs({
                                            category: 'Global Search Category',
                                            context: `Colleges`,
                                            location: 'Global search CTA',
                                        })}
                                    >
                                        Colleges
                                    </Button>
                                    <Button
                                        variant="dark"
                                        size="small"
                                        onClick={() => handleTypeFilterClick('highSchools')}
                                        {...getClickAttrs({
                                            category: 'Global Search Category',
                                            context: `High Schools`,
                                            location: 'Global search CTA',
                                        })}
                                    >
                                        High Schools
                                    </Button>
                                    <Button
                                        variant="dark"
                                        size="small"
                                        onClick={() => handleTypeFilterClick('paidHits')}
                                        {...getClickAttrs({
                                            category: 'Global Search Category',
                                            context: `Paid Hits`,
                                            location: 'Global search CTA',
                                        })}
                                    >
                                        Paid Hits
                                    </Button>
                                    <Button
                                        variant="dark"
                                        size="small"
                                        onClick={() => handleTypeFilterClick(paidHitClubs)}
                                        {...getClickAttrs({
                                            category: 'Global Search Category',
                                            context: `Paid Hitters`,
                                            location: 'Global search CTA',
                                        })}
                                    >
                                        Paid Hitters
                                    </Button>
                                    <Button
                                        variant="dark"
                                        size="small"
                                        onClick={() => handleTypeFilterClick('juniorCircuits')}
                                        {...getClickAttrs({
                                            category: 'Global Search Category',
                                            context: `Junior Circuits`,
                                            location: 'Global search CTA',
                                        })}
                                    >
                                        Junior Circuits
                                    </Button>
                                    <Button
                                        variant="dark"
                                        size="small"
                                        onClick={() => handleTypeFilterClick('collegeCircuits')}
                                        {...getClickAttrs({
                                            category: 'Global Search Category',
                                            context: `College Circuits`,
                                            location: 'Global search CTA',
                                        })}
                                    >
                                        College Circuits
                                    </Button>
                                    <Button
                                        variant="dark"
                                        size="small"
                                        onClick={() => handleTypeFilterClick('juniorRegionals')}
                                        {...getClickAttrs({
                                            category: 'Global Search Category',
                                            context: `Junior Regionals`,
                                            location: 'Global search CTA',
                                        })}
                                    >
                                        Junior Regionals
                                    </Button>
                                    <Button
                                        variant="dark"
                                        size="small"
                                        onClick={() => handleTypeFilterClick('camps')}
                                        {...getClickAttrs({
                                            category: 'Global Search Category',
                                            context: `Camps`,
                                            location: 'Global search CTA',
                                        })}
                                    >
                                        Camps
                                    </Button>
                                </>
                            )}
                            {showPickleballPills && (
                                <>
                                    <Button
                                        variant="dark"
                                        size="small"
                                        onClick={() => handleTypeFilterClick('pickleball')}
                                        {...getClickAttrs({
                                            category: 'Global Search Category',
                                            context: `Pickleball`,
                                            location: 'Global search CTA',
                                        })}
                                    >
                                        Pickleball
                                    </Button>
                                    {isAdultTeamPickballEnabled && (
                                        <Button
                                            variant="dark"
                                            size="small"
                                            onClick={handleTeamLeagueSearchClick(UT_ADULT_TEAM_PICKLEBALL)}
                                            {...getClickAttrs({
                                                category: 'Global Search Category',
                                                context: `Adult Team Pickleball`,
                                                location: 'Global search CTA',
                                            })}
                                        >
                                            Adult Team Pickleball
                                        </Button>
                                    )}
                                    {isJuniorTeamPickballEnabled && (
                                        <Button
                                            variant="dark"
                                            size="small"
                                            onClick={handleTeamLeagueSearchClick(UT_JUNIOR_TEAM_PICKLEBALL)}
                                            {...getClickAttrs({
                                                category: 'Global Search Category',
                                                context: `Junior Team Pickleball`,
                                                location: 'Global search CTA',
                                            })}
                                        >
                                            Junior Team Pickleball
                                        </Button>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                )}
                {hasNoResults && <div className={styles.globalSearchDropdownNoMatches}>No Matches Found</div>}
                {hasSomeResults && (
                    <div className={styles.globalSearchDropdownOptions}>
                        {!!players?.hits.length && (
                            <>
                                <div className={styles.searchTypeTitle}>
                                    Players
                                    <Button
                                        onClick={(e) => handleSeeAllClick(e, 'players')}
                                        {...getClickAttrs({
                                            category: 'Global Search See All',
                                            context: `See all players: ${searchQuery}`,
                                            location: 'Global search CTA',
                                        })}
                                    >
                                        SEE ALL <i className="fas fa-long-arrow-alt-right bounce-right" />
                                    </Button>
                                </div>
                                {players.hits.map((player, i) => renderPlayer(player.source, i))}
                            </>
                        )}

                        {!!events?.hits.length && (
                            <>
                                <div className={styles.searchTypeTitle}>
                                    Events
                                    <Button
                                        onClick={(e) => handleSeeAllClick(e, 'events')}
                                        {...getClickAttrs({
                                            category: 'Global Search See All',
                                            context: `See all events: ${searchQuery}`,
                                            location: 'Global search CTA',
                                        })}
                                    >
                                        SEE ALL <i className="fas fa-long-arrow-alt-right bounce-right" />
                                    </Button>
                                </div>
                                {events.hits.map((event, i) => renderEvent(event.source, i))}
                            </>
                        )}
                        {!!colleges?.hits.length && (
                            <>
                                <div className={styles.searchTypeTitle}>
                                    Colleges
                                    <Button
                                        onClick={(e) => handleSeeAllClick(e, 'colleges')}
                                        {...getClickAttrs({
                                            category: 'Global Search See All',
                                            context: `See all colleges: ${searchQuery}`,
                                            location: 'Global search CTA',
                                        })}
                                    >
                                        SEE ALL <i className="fas fa-long-arrow-alt-right bounce-right" />
                                    </Button>
                                </div>
                                {colleges.hits.map((school, i) => renderSchool(school.source, i))}
                            </>
                        )}
                        {!!highSchools?.hits.length && (
                            <>
                                <div className={styles.searchTypeTitle}>
                                    High Schools
                                    <Button
                                        onClick={(e) => handleSeeAllClick(e, 'highSchools')}
                                        {...getClickAttrs({
                                            category: 'Global Search See All',
                                            context: `See all high schools: ${searchQuery}`,
                                            location: 'Global search CTA',
                                        })}
                                    >
                                        SEE ALL <i className="fas fa-long-arrow-alt-right bounce-right" />
                                    </Button>
                                </div>
                                {highSchools.hits.map((school, i) => renderSchool(school.source, i))}
                            </>
                        )}
                        {!!clubs?.hits.length && (
                            <>
                                <div className={styles.searchTypeTitle}>
                                    Clubs
                                    <Button
                                        onClick={(e) => handleSeeAllClick(e, 'clubs')}
                                        {...getClickAttrs({
                                            category: 'Global Search See All',
                                            context: `See all clubs: ${searchQuery}`,
                                            location: 'Global search CTA',
                                        })}
                                    >
                                        SEE ALL <i className="fas fa-long-arrow-alt-right bounce-right" />
                                    </Button>
                                </div>
                                {clubs.hits.map((club, i) => renderClub(club.source, i))}
                            </>
                        )}
                        {isLoading && (
                            <div className={styles.loading}>
                                <i className="fas fa-circle-notch fa-spin" />
                                Loading...
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div>
            <div className="row">
                <div className="col-12 col-md-10 offset-md-1">
                    <div className={`${styles.globalSearchContainer} ${styles.hasMarketingLinks}`} ref={wrapperRef}>
                        <div className={styles.globalSearchInputContainer}>
                            <span className={`utr-icon ${showDropDown && styles.searchIconDropDownOpen} ${styles.feedbackLeft}`}>
                                <UtrIcon icon={SearchIcon} size="30px" alt="search" style={{ marginTop: 5 }} />
                            </span>
                            <input
                                type="text"
                                ref={queryRef}
                                className={`form-control ${styles.globalSearchInput} ${showDropDown && 'dropdown-open'}`}
                                onKeyUp={handleKeyUp}
                                onPaste={handlePaste}
                                onFocus={handleOnFocus}
                                placeholder="Search"
                            />
                            {!!searchQuery.length && (
                                <span
                                    className={`form-control-feedback fas fa-times-circle ${styles.feedbackRight}`}
                                    onClick={() => handleClearClick()}
                                />
                            )}
                        </div>
                        {showDropDown && <div className={styles.globalSearchDropdownContainer}>{renderDropdown()}</div>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GlobalSearch;
