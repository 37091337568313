export const LeagueSessionTypes = {
    TOURNAMENT: 1,
    MULTI_ROUND: 2,
};

export const FrequencyTypes = {
    ONCE_A_WEEK: 1,
    EVERY_TWO_WEEKS: 2,
};

export const MatchFormatTypes = {
    SINGLES: 1,
    DOUBLES: 2,
    BOTH: 3,
};

export const ResultTypes = {
    COMPLETED: 1,
    COMPLETED_WITH_PARTIAL_SCORES: 2,
    PLAYER_OR_TEAM_DEFAULTED: 3,
    WITHDREW: 4,
    RETIRED: 5,
    INCOMPLETE: 6,
    DEFAULTED: 7,
    MATCH_NOT_PLAYED: 8,
};

export const ScheduleStatuses = {
    DRAFT: 1,
    PUBLISHED: 2,
};

export const TennisScoringFormatTypes = {
    BEST_OF_THREE: 1,
    TWO_SETS_WITH_TIEBREAKER: 2,
    SINGLE_SET: 3,
    TIME_BASED: 4,
    FAST_FOUR: 5,
};

export const PickleballScoringFormatTypes = {
    SINGLE_GAME: 6,
    BEST_OF_THREE_GAMES: 7,
    TIME_BASED_GAME: 8,
};

export const StatusTypes = {
    OPEN: 1,
    CLOSED: 2,
};

export const WithdrawTypes = {
    WITHDRAW: 1,
    REFUND: 2,
    WITHDRAW_AND_REFUND: 3,
};

export const RequestStatusTypes = {
    ACCEPTED: 1,
    DENIED: 2,
    PENDING: 3,
};

export const LeagueGenderTypes = {
    CO_ED: 1,
    MALE: 2,
    FEMALE: 3,
};

export const LeagueTypes = {
    UT_ADULT_TEAM_TENNIS: 1,
    UT_JUNIOR_TEAM_TENNIS: 3,
    CLUB_ADULT_LEAGUE_TENNIS: 4,
    OTHER_TENNIS: 5,
    UT_ADULT_TEAM_PICKLEBALL: 6,
    UT_JUNIOR_TEAM_PICKLEBALL: 7,
    CLUB_ADULT_LEAGUE_PICKLEBALL: 8,
    OTHER_PICKLEBALL: 9,
};

export const LeagueTierTypes = {
    ADULT: 1,
    JUNIOR: 2
};

export const PlayerActionTypes = {
    WITHDRAW: 1,
    MOVE_TO_TEAM: 2,
    COPY_TO_TEAM: 3,
    MARK_AS_ROSTERED: 4,
    MARK_AS_SUBSTITUTE: 5,
};

export const OrderByTypes = {
    TEAM_NAME: 0,
    SESSION_NAME: 1,
    RANK: 2,
};

export const OrderByRuleTypes = {
    ASCENDING: { id: 0, value: 'asc' },
    DESCENDING: { id: 1, value: 'desc' },
};

export const SessionDrawTypes = {
    FIRST_MATCH_BACK_DRAW: 1,
    SINGLE_ELIMINATION: 2
};

export const MultiRoundScheduleTypes = {
    BY_ROUND: 0,
    BY_DATE: 1
};

export const LeagueSanctionTypes = {
    USAP: 1,
};

export const genderTypes = [
    { id: LeagueGenderTypes.CO_ED, value: 'all', label: 'Co-ed', description: 'Co-ed' },
    { id: LeagueGenderTypes.MALE, value: 'm', label: 'M', description: 'Male' },
    { id: LeagueGenderTypes.FEMALE, value: 'f', label: 'F', description: 'Female' },
];

export const distanceTypes = [
    { id: 10, description: '10 miles' },
    { id: 25, description: '25 miles' },
    { id: 50, description: '50 miles' },
    { id: 75, description: '75 miles' },
    { id: 100, description: '100 miles' },
    { id: 125, description: '125 miles' },
];

export const sessionTypes = [
    { id: LeagueSessionTypes.TOURNAMENT, description: 'Team Tournament' },
    { id: LeagueSessionTypes.MULTI_ROUND, description: 'Multi-Round' },
];

export const multiRoundScheduleTypes = [
    { id: MultiRoundScheduleTypes.BY_ROUND, description: 'By Round' },
    { id: MultiRoundScheduleTypes.BY_DATE, description: 'By Date' },
];

export const matchFrequencyTypes = [
    { id: FrequencyTypes.ONCE_A_WEEK, description: 'Once a week' },
    { id: FrequencyTypes.EVERY_TWO_WEEKS, description: 'Every 2 weeks' },
];

export const tennisScoringTypes = [
    { id: TennisScoringFormatTypes.BEST_OF_THREE, description: 'Best of Three Sets' },
    { id: TennisScoringFormatTypes.TWO_SETS_WITH_TIEBREAKER, description: 'Two Sets w/ Match Tiebreaker' },
    { id: TennisScoringFormatTypes.SINGLE_SET, description: 'Single Set' },
    { id: TennisScoringFormatTypes.TIME_BASED, description: 'Time Based' },
    { id: TennisScoringFormatTypes.FAST_FOUR, description: 'Fast Four' },
];

export const pickleballScoringTypes = [
    { id: PickleballScoringFormatTypes.SINGLE_GAME, description: 'Single Game' },
    { id: PickleballScoringFormatTypes.BEST_OF_THREE_GAMES, description: 'Best of Three Games' },
    { id: PickleballScoringFormatTypes.TIME_BASED_GAME, description: 'Time Based' },
];

export const durationTypes = [
    { id: 1, description: '30 min' },
    { id: 2, description: '60 min' },
    { id: 3, description: '90 min' },
    { id: 4, description: '120 min' },
    { id: 5, description: 'Unlimited' },
];

export const tierTypes = [
    { id: LeagueTierTypes.ADULT, description: 'Adult' },
    { id: LeagueTierTypes.JUNIOR, description: 'Junior' },
];

export const openClosedStatusTypes = [
    { id: StatusTypes.OPEN, description: 'Open' },
    { id: StatusTypes.CLOSED, description: 'Closed' },
];

export const sessionMatchFormatTypes = [
    { id: MatchFormatTypes.SINGLES, description: 'Singles' },
    { id: MatchFormatTypes.DOUBLES, description: 'Doubles' },
    { id: MatchFormatTypes.BOTH, description: 'Singles and Doubles' },
];

export const drawTypes = [
    { id: SessionDrawTypes.FIRST_MATCH_BACK_DRAW, description: 'First match back draw' },
    { id: SessionDrawTypes.SINGLE_ELIMINATION, description: 'Single elimination' },
];

export const weekdays = [
    { id: 0, description: 'Sunday' },
    { id: 1, description: 'Monday' },
    { id: 2, description: 'Tuesday' },
    { id: 3, description: 'Wednesday' },
    { id: 4, description: 'Thursday' },
    { id: 5, description: 'Friday' },
    { id: 6, description: 'Saturday' },
];

export const playerWithdrawTypes = [
    { id: WithdrawTypes.WITHDRAW, description: 'Withdraw' },
    { id: WithdrawTypes.REFUND, description: 'Refund' },
    { id: WithdrawTypes.WITHDRAW_AND_REFUND, description: 'Withdraw & Refund' },
];

export const matchResultTypes = [
    { id: ResultTypes.COMPLETED, description: 'Completed' },
    { id: ResultTypes.COMPLETED_WITH_PARTIAL_SCORES, description: 'Completed with parital scores' },
    { id: ResultTypes.DEFAULTED, description: 'Defaulted' },
    { id: ResultTypes.WITHDREW, description: 'Withdrew' },
    { id: ResultTypes.RETIRED, description: 'Retired' },
    { id: ResultTypes.INCOMPLETE, description: 'Incomplete' },
    { id: ResultTypes.MATCH_NOT_PLAYED, description: 'Match not played' },
];

export const PlayerManageOptions = [
    { id: PlayerActionTypes.WITHDRAW, description: 'Withdraw' },
    { id: PlayerActionTypes.MOVE_TO_TEAM, description: 'Move to team' },
    { id: PlayerActionTypes.COPY_TO_TEAM, description: 'Copy to team' },
    { id: PlayerActionTypes.MARK_AS_SUBSTITUTE, description: 'Mark as Substitute Player' },
    { id: PlayerActionTypes.MARK_AS_ROSTERED, description: 'Mark as Rostered Player' },
];

export const DateFilterTypes = {
    UPCOMING: 1,
    IN_PROGRESS: 2,
    PAST: 3
};

export const searchDateFilterTypes = [
    { id: DateFilterTypes.UPCOMING, description: 'Upcoming' },
    { id: DateFilterTypes.IN_PROGRESS, description: 'In progress' },
    { id: DateFilterTypes.PAST, description: 'Past' },
];

export const TournamentTeamsCount = {
    FOUR: 4,
    EIGHT: 8,
    SIXTEEN: 16,
};

export const tournamentTeamsCountOptions = [
    { id: TournamentTeamsCount.FOUR, description: '4 teams / 2 matches' },
    { id: TournamentTeamsCount.EIGHT, description: '8 teams / 4 matches' },
    { id: TournamentTeamsCount.SIXTEEN, description: '16 teams / 8 matches' },
];

export const LeagueSearchEntities = {
    CONFERENCES: 'conferences',
    SESSIONS: 'sessions',
};

export const SportTypeIds = {
    TENNIS: 1,
    PICKLEBALL: 2
};

export const SportTypes = {
    1: 'Tennis',
    2: 'Pickleball'
};

export const sportTypes = [
    { id: SportTypeIds.TENNIS, description: 'Tennis' },
    { id: SportTypeIds.PICKLEBALL, description: 'Pickleball' },
];

export const getTypeForId = (id, types) => {
    return types.find((type) => type.id === id);
};

export const PlayerAvailabilityStatusTypes = {
    YES: 1,
    MAYBE: 2,
    NO: 3,
    NOT_SET: 4,
};

export const PlayerAvailabilityStatus = {
    1: 'YES',
    2: 'MAYBE',
    3: 'NO',
};

export const PlayerAvailabilityColors = {
    GREEN: 'rsvpGreen',
    ORANGE: 'rsvpOrange',
    RED: 'rsvpRed',
};

export const SessionStandingsRules = {
    1: 'Win/Loss record',
    2: '% of Team Matches won',
    3: '% of Games won',
    4: 'Win/Loss record',
    5: '% of Team Matches won',
    6: '% of Points won'
};

export const SessionStandingsTypes = {
    TENNIS_WIN_LOSS_RECORD: 1,
    TENNIS_PERCENT_OF_TEAM_MATCHES_WON: 2,
    TENNIS_PERCENT_OF_GAMES_WON: 3,
    PICKLEBALL_WIN_LOSS_RECORD: 4,
    PICKLEBALL_PERCENT_OF_TEAM_MATCHES_WON: 5,
    PICKLEBALL_PERCENT_OF_POINTS_WON: 6,
};

export const tennisSessionStandingsOptions = [
    { id: SessionStandingsTypes.TENNIS_WIN_LOSS_RECORD, description: 'Win/Loss record' },
    { id: SessionStandingsTypes.TENNIS_PERCENT_OF_TEAM_MATCHES_WON, description: '% of Team Matches won' },
    { id: SessionStandingsTypes.TENNIS_PERCENT_OF_GAMES_WON, description: '% of Games won' },
];

export const pickleballSessionStandingsOptions = [
    { id: SessionStandingsTypes.PICKLEBALL_WIN_LOSS_RECORD, description: 'Win/Loss record' },
    { id: SessionStandingsTypes.PICKLEBALL_PERCENT_OF_TEAM_MATCHES_WON, description: '% of Team Matches won' },
    { id: SessionStandingsTypes.PICKLEBALL_PERCENT_OF_POINTS_WON, description: '% of Points won' },
];

export const SearchSortTypes = {
    START_DATE: 'startdate',
    AGE: 'age',
    GENDER: 'gender',
    UTR: 'utr',
    UTRP: 'utrp'
};

export const SessionDateStatuses = {
    1: 'upcoming',
    2: 'inprogress',
    3: 'past',
};

export const SliderTypes = {
    AGE: 'Age',
    UTR: 'UTR',
    UTRP: 'UTRP'
};
